import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

function Footer(props) {
    return (
        <footer className="footer">
            <div className="container">
                <div className="col12">
                    <small className="copy">&copy;&nbsp;{(new Date().getFullYear())}&nbsp;North London Psychologists Ltd</small>
                    <small className="credit">
                        <a href="https://www.cyber-duck.co.uk/" target="_blank" rel="noopener noreferrer">Website by <span>Cyber-Duck</span></a> &nbsp;| &nbsp;
                        <StaticQuery 
                        query={graphql`
                            query {
                                allContentfulContentPage(filter: {showInFootMenu: {eq: true}}) {
                                    nodes {
                                        id
                                        slug
                                        menuLabel
                                    }
                                }
                            }
                        `}
                        render={data => (
                            data.allContentfulContentPage.nodes.map(page => (
                                <Link to={page.slug} key={page.id}>{page.menuLabel}</Link> 
                            ))
                        )}
                    />
                    </small>
                </div>
            </div>

            <div className="container reg-address">
                <div className="col8 pre2">
                    <p>Registered address: North London Psychologists Limited, 1 Sussex  Villas, Common Road, Stanmore, London HA73HX, United Kingdom (Company Number: 11700481)</p>
                </div>
            </div>
        </footer>
    );
}

export default  Footer
