import React from 'react';
import Header from '../components/header';
import Footer from './footer';
import CookieConsent from 'react-cookie-consent';

function Layout({ children }) {
    return (
        <div className="wrap">
            <Header />

            <div className="clear"></div>

            <section id="body">
                {children}
            </section>

            <Footer />

            <CookieConsent
                enableDeclineButton
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-analytics">
                    This site uses cookies to provide an improved experience. Learn more on our <a href="/privacy-policy">Privacy policy</a>.
            </CookieConsent>
        </div>
    );
}
export default Layout
