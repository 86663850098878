import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from "gatsby"

class Nav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: false
        }
    }

    render() {

        const { isActive } = this.state

        return (
            <nav className={`nav ${isActive ? 'menu-open' : ''}`} aria-expanded={isActive ? 'true' : 'false'} role="navigation" aria-label="Main navigation">
                <ul className="menu">
                    <StaticQuery 
                        query={graphql`
                            query {
                                allContentfulContentPage(sort: {fields: menuOrder, order: ASC}, filter: {showInTopMenu: {eq: true}}) {
                                    nodes {
                                        id
                                        slug
                                        menuLabel
                                    }
                                }
                            }
                        `}
                        render={data => (
                            data.allContentfulContentPage.nodes.map(page => (
                                <li key={page.id}><Link to={'/' + page.slug} activeClassName="current">{page.menuLabel}</Link></li>
                            ))
                        )}
                    />
                    <li><Link to="/testimonials" activeClassName="current" partiallyActive>Testimonials</Link></li>
                    <li><Link to="/blog" activeClassName="current" partiallyActive>Blog</Link></li>
                    <li><Link to="/contact" activeClassName="current">Contact</Link></li>
                </ul>

                <button className="btn-menu" onClick={()=> { this.setState({ isActive: !isActive }) }}>Menu</button>
            </nav>
        )
    }
}

export default Nav
