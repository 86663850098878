import React from "react"
import { Link } from "gatsby"
import Nav from "../components/nav"

function Header(props) {
    return (
        <header className="header">
            <div className="container">
                <div className="col12">
                    <div className="head">
                        <Link className="logo" to="/">
                            <img src={`../../logo.png`} alt="North London Psychologists" width="65" />
                            <span className="brand">North London Psychologists</span>
                        </Link>
                        
                        <Nav />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header
